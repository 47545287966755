@import 'styles/layers.scss';

@layer component {
  .content {
    display: flex;
    overflow: auto;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    scrollbar-gutter: stable both-edges;
  }
}